import QRCode from "qrcode.react";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, onValue,set } from "firebase/database";
// import { getDatabase, ref, set } from "firebase/database";

// get data from firebase database

export default function Wqr() {

          
    const firebaseConfig = {
      apiKey: "AIzaSyBdEMKFJbcFGOaQ-Te9H_E3OWgdAYUBouo",
      authDomain: "mudassir-acede.firebaseapp.com",
      databaseURL: "https://mudassir-acede.firebaseio.com",
      projectId: "mudassir-acede",
      storageBucket: "mudassir-acede.appspot.com",
      messagingSenderId: "1015674312372",
      appId: "1:1015674312372:web:8fc302456af2bcff1d29e1",
      measurementId: "G-XD2X60D54B"
  };
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);


    function writeUserData(userId, name, email, imageUrl) {
      const db = getDatabase();
      set(ref(db, 'users/' + userId), {
        username: name,
        email: email,
      //   profile_picture : imageUrl
      });
    }

//     writeUserData('1235', 'mudassir', 'md2@123')
    
//     const db = getDatabase();
// const starCountRef = ref(db, 'users/1234' );
// onValue(starCountRef, (snapshot) => {
//   const data1 = snapshot.val();
//   console.log(data1);
// //   updateStarCount(postElement, data);
// });



   return (
         <div>

         </div>

   );
}