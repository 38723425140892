import logo from './logo.svg';
import './App.css';
//import header from './components/header';
import Navbar from './components/Navbar';
import Body from './components/body';
import { About } from './components/About';
import Footer from './components/Footer';
import NewsFeed from './components/NewsFeed';
import Claint from './components/Claint';
import Search from './components/Search';

import Wqr from './components/Wqr';

import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import CurrencyConverter from './components/CurrencyConverter';

// this is a variable deleting and updating does not work
// const todolist=[
//   {
//     s:"m1"
//   },
//   {
//     s:"m2"
//   }
// ]



function App() {

  // this is a state should be called in the fn
  const [todolist, settodolist] = useState([
    {
      s: "m1"
    },
    {
      s: "m2"
    },
    {
      s: "m3"
    },
    {
      s: "m4"
    }
  ])


  const delitem = (item) => {
    console.log("item deleted", item)

    settodolist(todolist.filter((e) => {
      return e !== item;
    }));
  }



  return (
    <>

      <Router>
        {/* <Navbar title="Currency " title2=" converter" show={false} /> */}
        {/* parent to child props.title props.title2  */}
        {/* {props.show ? <></> :<></>} */}

        <Routes>

          {/* <Route path="/about" element={<About list={todolist} key={todolist} del={delitem} />}> */}

          {/* </Route> */}

          <Route path='/' element={<Search />}></Route>

          {/* <Route path="/cc" render={() => {
            return (
              <>
                <CurrencyConverter />
              </>
            )
          }}> */}

          {/* </Route> */}

          <Route path="/:id" element={<Body />}>

          </Route>



        </Routes>

    
<Wqr/>
        {/* <Footer /> */}

      </Router>

    </>

  );
}

export default App;
