import React from 'react'

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom";

import QRCode from "qrcode.react";

// import data from './Data';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, set, push } from "firebase/database";
import { child, get } from "firebase/database";

// const data = {
//     1234: { id: 1234, name: 'Mohammed Mudassir', age: 20 },
//     1235: { id: 1235, name: 'nooreain', age: 20 },
//     1236: { id: 1236, name: 'maaz', age: 20 },
// }



let data1


const Body = () => {
  const param = useParams()


  const firebaseConfig = {
    apiKey: "AIzaSyBdEMKFJbcFGOaQ-Te9H_E3OWgdAYUBouo",
    authDomain: "mudassir-acede.firebaseapp.com",
    databaseURL: "https://mudassir-acede.firebaseio.com",
    projectId: "mudassir-acede",
    storageBucket: "mudassir-acede.appspot.com",
    messagingSenderId: "1015674312372",
    appId: "1:1015674312372:web:8fc302456af2bcff1d29e1",
    measurementId: "G-XD2X60D54B"
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);



  function writeUserData(name, email) {
    const db = getDatabase();

    push(ref(db, 'users/'), {
      username: document.getElementById('inputname').value,
      domain: document.getElementById('inputdomain').value,
      date: document.getElementById('inputdate').value
      
    });
    console.log("data uploaded")
    document.getElementById('inputname').value = ""
  }



  function getdataa(param) {
    const dbRef = ref(getDatabase());
    get(child(dbRef, 'users/' + param.id)).then((snapshot) => {
      if (snapshot.exists()) {
        data1 = snapshot.val();
        console.log(data1.username,data1.domain,data1.date);
        makecanvas(data1.username,data1.domain,data1.date)
      } else {
        console.log("No data available");
        document.getElementById('invalid').innerHTML = "invalid id"
        document.getElementById('searchid').innerHTML = "Search id"

      }
    }).catch((error) => {
      console.error(error);
    });

  }

  function showdataa(param) {
    // how to print all the data present in a file in firebase
    const dbRef = ref(getDatabase());
    get(child(dbRef, 'users/' )).then((snapshot) => {
      if (snapshot.exists()) {
        data1 = snapshot.val();
        let ky=Object.keys(data1)
        console.log(Object.keys(data1));
        for(let i=0;i<ky.length;i++){
          console.log(ky[i])
          console.log(data1[ky[i]].username)
          var div = document.getElementById('content');
          var p = document.createElement('p');

          p.textContent = i+') '+ky[i]+'  ===>  '+data1[ky[i]].username;
          
          div.appendChild(p);
          
        }

      } else {
        console.log("No data available");

      }
    }).catch((error) => {
      console.error(error);
    });

  }


  function makecanvas(dataname,datadomain,datadate='') {
    if(datadate!=''){    datadate='during the period of '+datadate
  }
    
    setTimeout(() => {
  
      console.log("canvas made")
  
  
      var canvas = document.getElementById("DemoCanvas");
      var ctx = canvas.getContext('2d');
  
      const wi = 900;
      const hi = 600;
  
      canvas.width = wi;
      canvas.height = hi;
  
      let currentX = 200, currentY = hi / 2;
  
  
      const qrcanvas = document.getElementById("qrcode-container").querySelector("canvas");
      const dataURL = qrcanvas.toDataURL();
      console.log(dataURL);
  
  
      var iurl = dataURL, bgimg = "/ct2.png", nam = "mudassir"
      let base_image = new Image();
      base_image.src = bgimg;
      base_image.onload = function () {
      resetCanvas();
      };
  
  
      var min = 0, max = 200
      var sx = max / 2
      pimg(iurl)
  
      function imgupdate() {
        iurl = document.getElementById("img1url").value
        console.log(iurl)
        // p_image.src = iurl;
        pimg(iurl)
      }
      let i, sh, lenfor, image;
  
      const resetCanvas = () => {
        ctx.drawImage(base_image, 0, 0, wi, hi);
        pimg(iurl)
        for (i = 0; i < txt.length; i++) {
          setInterval(name1(i), 100);
          setInterval(ctx.fillText(txt[i], tx[i], ty[i]), 100);
        };
  
      }
  
  
      function pimg(iurl2) {
        let p_image = new Image();
        p_image.src = iurl2;
        p_image.width = 100
        p_image.height = 100
        p_image.onload = function () {
          setTimeout(() => {
            resetCanvas();
            // ctx.drawImage(p_image,currentX- sx/2,currentY-sx/2,sx,sx);
  
            ctx.drawImage(p_image, 400, 470, 100, 100);
          }, 5);
        };
      }
  
  
      let txtlength = dataname.length
  
      let tx = [(wi / 2) -( txtlength * 12.5), 600, 285], ty = [330, 413, 459], ts = [25, 10, 25], fs = [40, 20, 20], txt = [dataname,datadomain,datadate, "", ""], txtwidth = [];
      for (i = 0; i < txt.length; i++) { lenfor = txt[i].length; txtwidth[i] = [(fs[i] / 2) * lenfor]; }
      ctx.fillStyle = "black";
  
  
  
  
      function bg() {
        base_image = new Image();
        base_image.src = bgimg;
        base_image.onload = function () {
        };
      }
  
  
  
      function name1(i) {
        ctx.font = fs[i] + "px serif";
  
      }
  
  
  
  
    }, 1);

    
  
  }
  
  function gturl() {
    console.log("download")
    console.log("downloaded done")
  }

  
function download(){
  console.log("download")
    var canvas = document.getElementById("DemoCanvas");
    var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    var link = document.createElement('a');
    link.download = "certificate.png";
    link.href = image;
    link.click();

}


  if (param.id == 'admin9880055625') {

    

    return (
      <div>
        <p>admin</p>
        <br />
        input name <input type="text" id="inputname" />
        <br />
        input domain <input type="text" id="inputdomain" />
        <br />
        input date <input type="text" id="inputdate" />
        <br />
        <button onClick={() => { writeUserData() }}>update</button>
        <br /><br />
        <button onClick={() => { showdataa() }}>show</button>
        <br /><br />
        <div id='content' style={{overflow:'scroll',width:'100%',height:'500px',padding:'20px'}} >
         <p >List</p>
      </div>
        
        
      </div>
    );

  }

  else {
    return (
      <div>


        <canvas id="DemoCanvas" ></canvas>

        <button onClick={() => { download() }}>Download</button>

        {/* <p>This id is valid  </p> */}
        {/* <p>{data[param.id].id}</p>
                <p>{data[param.id].name}</p> */}

        <div id="qrcode-container" >
          <QRCode
            value={window.location.href} style={{ marginRight: 50, display: 'none' }} />
        </div>
        {getdataa(param)}

        <p id='invalid'></p>
        <a href="/" id='searchid'></a>


      </div>
    );
  }



}

export default Body;




const Search = () => {
  const navigate = useNavigate();
  navigate(document.getElementById('id').value);

}



