// const About = () => {
//     return (

//         <div classNameName="nav">
//             <img src=" https://1.bp.blogspot.com/-cW4bVLiiyOw/X46T7MeWpAI/AAAAAAAAAFk/CLbOWy7gVUELkgV5PPrl76tN_KelZkXgQCLcBGAsYHQ/s320/IMG_20200510_160855_831.jpg "  alt=""></img>        
//             <h1>Mudassir</h1>
//             <p1>My main aim is to get good review from you I can design a professional website for you .I will do all the SEO settings like xml sitemap, favicon,indexing, meta tag,title, description, keyword research, websites funnels,custom robot.txt,on page SEO,Google analytics, Google web master.</p1>  
                
//         </div>
//       );
// }
 
// export default About;


// react function components
// import React from 'react'

// export default function About() {
//   return (
//     <>
//     <div>About</div>
//     <h1>thisis about mudassir</h1>
//     </>
//   )
// }

// rfc import About from './components/About';
// rafc import {About} from './components/About';

// react arrow fn com  use {} while importing Ex import {About} from './components/About';
import React from 'react'
import Addtodo from './Addtodo'

export const About = ({list,del}) => {
  return (<>
    <div>About</div>
    <Addtodo/>
    <br /><br />
    {list.map((lst)=>{
        return(
        <>
        <h3>{lst.s}</h3>
        <button onClick={()=>{del(lst)}}>delete</button>
        </>
        )
    })    }
    </>
  )
}
