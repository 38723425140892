import React, { Component } from 'react'

import { useNavigate } from "react-router-dom";

// export default class Search extends Component {

    
//   render() {
//     const navigate = useNavigate();

//     return(
//         <div>
//             <p>enter your id</p>
//             <input type="text" id="id" placeholder="id"/>
//             <button type="submit" onClick={navigate(document.getElementById('id').value)}></button>
//         </div>
//     );

    
//   }
// }


const Search = () => {
      
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    
    navigate(document.getElementById('id').value);
  }
        return(
        <div>
<center>
<br /><br />
            <p>Enter your ID</p>
            <input type="text" id="id" placeholder="id"/>
            <button type="submit" onClick={routeChange}>search</button>
        
      <style dangerouslySetInnerHTML={{__html: "\n/* Style the paragraph element */\np {\n  font-size: 18px;\n  margin-bottom: 10px;\n}\n\n/* Style the input field */\ninput[type=\"text\"] {\n  padding: 10px;\n  border: 2px solid #4777F5;\n  border-radius: 5px;\n  font-size: 16px;\n  width: 200px;\n  outline: none;\n  box-shadow: 0px 2px 5px #ccc; /* Add a box shadow */\n  margin-right: 10px; /* Add some spacing */\n}\n\n/* Style the search button */\nbutton[type=\"submit\"] {\n  background-color: #4777F5;\n  color: #fff;\n  border: none;\n  border-radius: 5px;\n  font-size: 16px;\n  padding: 10px 20px;\n  cursor: pointer;\n  box-shadow: 0px 2px 5px #ccc; /* Add a box shadow */\n}\n\n/* Change the search button color on hover */\nbutton[type=\"submit\"]:hover {\n  background-color: #2124B1;\n}\n\n            " }} />
            </center>
        </div>
    );

}


export default Search;